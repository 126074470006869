import axios from "axios";
import serverUrl from "../config/serverUrl";


const API_URL = serverUrl;
const token = localStorage.getItem('token');

export const fetchProductReview = async (productId) => {
    try {
        const response = await axios.get(`${API_URL}/products/${productId}/reviews`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        console.log(response.data)
        return response.data;

    } catch (error) {
        return error
    }
};

export const postProductReview = async (productId, data) => {

    console.log(data)

    const response = await axios.post(`${API_URL}/products/${productId}/reviews`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });

    return response.data;
};
